/*
=========================================================
=========================================================

* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';
import useLocalStorage from "use-local-storage";
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'

function Presentation() {
    const [deviceId, setDeviceId] = useLocalStorage("deviceId", uuidv4());
    const [deviceData, setDeviceData] = useLocalStorage("deviceData", {_id: deviceId, scripts:[]});

    const [changed, setChanged] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const loadFromDb = async ()=>{
        const dd = await axios.get('/api/scripts/'+searchParams.get('id'))
        let s = []
        for(let a in dd.data.scripts){
          s[a]=dd.data.scripts[a]
        }
        dd.data.scripts = s
        setDeviceData(dd.data)
        setChanged(true)
      }
    useEffect(() => {
        //setCurrentScript(deviceData.scripts[scriptIndex])
        if(searchParams.get('id')){
            setDeviceId(searchParams.get('id'))
            loadFromDb()
        }
    }, [deviceId]);
    useEffect(() => {
        //setCurrentScript(deviceData.scripts[scriptIndex])
        if(changed){
            
            navigate("/home");
        }
    }, [changed]);
      
    /*
    
    */
    //navigate("/home");
    return <div>Loading ...</div>
}

export default Presentation;
