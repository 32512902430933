/**
=========================================================
=========================================================

* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base breakpoints for the Material Kit 2 React.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Material Kit 2 React using thie file.
 */

export default {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};
