/*
=========================================================
=========================================================

* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import a from "assets/json/options/1.json"
import b from "assets/json/options/2.json"
import c from "assets/json/options/3.json"
import d from "assets/json/options/4.json"
import e from "assets/json/options/5.json"
import f from "assets/json/options/6.json"
import g from "assets/json/options/7.json"
import txt from "assets/json/txt.json"
import Input from "assets/theme/components/form/input";
import { v4 as uuidv4 } from 'uuid';
import useLocalStorage from "use-local-storage";
import Button from "assets/theme/components/button";
import axios from 'axios'
const questions = [a,b,c,d,e,f,g]
const defaultValue = {script:' ', example:' '}
function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}
function Presentation() {
  const scriptTemplate = {name:"Untitled", txts:txt, choice:{}}
  const [synced, setSynced] = useLocalStorage("synced", false);
  const [deviceId, setDeviceId] = useLocalStorage("deviceId", uuidv4());
  const [deviceData, setDeviceData] = useLocalStorage("deviceData", {_id: deviceId, scripts:[scriptTemplate]});
  const [scriptIndex, setScriptIndex] = useLocalStorage('scriptIndex', 0)
  const [scripts, setScripts] = useState(deviceData.scripts)
  const [currentScript, setCurrentScript] = useState(scripts[scriptIndex])
  const [currentScriptName, setCurrentScriptName] = useState(scripts[scriptIndex].name)
  const [expanded, setExpanded] = useLocalStorage('expanded','accordian_0');
  const [myTxt, setMyTxt] = useState(defaultValue)
  const [myTxts, setMyTxts] = useState(currentScript.txts)//useLocalStorage('myTxts', currentScript.txts)
  const [myChoices, setMyChoices] = useState(currentScript.choice) //useLocalStorage('myChoices',currentScript.choice)
  const saveToDb = async ()=>{
    const dd = getCopy(deviceData)
    for(let a in dd.scripts){
      for(let b in dd.scripts[a].txts){
        delete dd.scripts[a].txts[b].example
      }
    }
    await axios.put('/api/scripts/'+deviceId, dd)
    
  }
  const loadFromDb = async ()=>{
    const dd = await axios.get('/api/scripts/'+deviceId)
    console.log(dd.data)
    //
    let s = []
    for(let a in dd.data.scripts){
      s[a]=dd.data.scripts[a]
    }
    dd.data.scripts = s
    console.log(dd.data.scripts)
    sets(dd.data)
    setCurrentScript(dd.data.scripts[scriptIndex])
    setCurrentScriptName(dd.data.scripts[scriptIndex].name)
    setExpanded('accordian_0')
    setMyTxt(dd.data.scripts[scriptIndex].choice['accordian_0']?dd.data.scripts[scriptIndex].txts[dd.data.scripts[scriptIndex].choice['accordian_0']]:defaultValue)
    setMyTxts(dd.data.scripts[scriptIndex].txts)
    setMyChoices(dd.data.scripts[scriptIndex].choice)
  }
  const updateAccordianChange = (panel) => {
    console.log("something", panel)
    setExpanded(panel)
    if(myChoices[panel]){
      setMyTxt(myTxts[myChoices[panel]])
    }else{
      setMyTxt(defaultValue)
    }
  };
  const accordianChanged = (panel) => (event, isExpanded) => {
    updateAccordianChange(panel)
  };
  const accordianSelectChanged = (event) => {
    let dd = getCopy(deviceData)
    let mc = dd.scripts[scriptIndex].choice
    mc[expanded] = event.target.value
    console.log('myChoices', mc)
    const text = myTxts[event.target.value]
    setMyTxt(text)
    setMyChoices(mc)
    sets(dd)
    
  };
  const accordianTextChanged = (event) => {
    //console.log(event.target.value)
    setMyTxt(event.target.value)
    let dd = getCopy(deviceData)
    
    let mt = dd.scripts[scriptIndex].txts
    mt[myChoices[expanded]].script = event.target.value
    setMyTxts(mt)
    sets(dd)
    
  };
  const sets = (dd)=>{
    setDeviceData(dd)
    setScripts(dd.scripts)
  }
  const getCopy = (obj)=>{
    return JSON.parse(JSON.stringify(obj))
  }
  const updateScriptName = event =>{
    let text = event.target.value
    let dd = getCopy(deviceData)
    dd.scripts[scriptIndex].name = text
    sets(dd)
    setCurrentScriptName(text)

  }
  const newScript = event=>{
    let dd = getCopy(deviceData)
    dd.scripts.push(scriptTemplate)
    setDeviceData(dd)
    setScriptIndex(dd.scripts.length-1)
    setScripts(dd.scripts)
    setCurrentScriptName(dd.scripts[dd.scripts.length-1].name)
  }
  const scriptSelectChange = event=>{
    console.log("setScriptIndex", event.target.value)
    setScriptIndex(event.target.value)
    setCurrentScriptName(deviceData.scripts[event.target.value].name)
    setMyTxt(deviceData.scripts[event.target.value].choice[expanded]?deviceData.scripts[event.target.value].txts[deviceData.scripts[event.target.value].choice[expanded]]:defaultValue)
    setMyTxts(deviceData.scripts[event.target.value].txts)
    setMyChoices(deviceData.scripts[event.target.value].choice)
  }
  useEffect(() => {
    //saveToDb()
    updateAccordianChange(expanded)
  }, [expanded]);
  useEffect(() => {
    //setCurrentScript(deviceData.scripts[scriptIndex])
  }, [deviceData]);
  return (
    <>
      <DefaultNavbar
        routes={[]}
        brand="Ultimate Script Generator"
        action={{
          type: "external",
          route: "https://www.ATeleSys.com",
          label: "Powered By ATeleSys",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Ultimate Script Generator{" "}
            </MKTypography>
            <MKInput label="Device ID" fullWidth value={deviceId} size="large" focused inputProps={{ style: { color: "white", fontSize:24 } }} />
            <MKBox mt={2} width="100%"><MKInput label="Script Name" fullWidth value={currentScriptName} onChange={updateScriptName} size="large" focused inputProps={{ style: { color: "white", fontSize:24 } }} />
            </MKBox>
            <MKBox mt={2} width="100%">
            
            </MKBox>

          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox pb={5}>
          <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 1000 }} fullWidth>
            <InputLabel shrink htmlFor="select-multiple-native-2">
                Scripts
              </InputLabel>
            <Select multiple={false}
            value={scriptIndex}
            onChange={scriptSelectChange}
            native
            inputProps={{
              id: 'select-multiple-native-2',
            }}>
                  {scripts.map((value, index)=>{
                    return <option key={'option1_'+index} value={index}>
                    {value.name}
                  </option>
                  })}
                  
            </Select>
            
          </FormControl>
          <MKButton onClick={newScript}>New Script</MKButton>
          <MKButton onClick={saveToDb}>Save to Server</MKButton>
          <MKButton onClick={loadFromDb}>Load from Server</MKButton>
        </MKBox>
        <div>{questions.map(({ title, items }, index) =><Accordion key={"accordian_"+index} expanded={expanded==="accordian_"+index} onChange={accordianChanged("accordian_"+index)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            key={"acc_"+index}
          >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={9}>
            <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 1000 }} fullWidth>
              <InputLabel shrink htmlFor="select-multiple-native">
                {title}
              </InputLabel>
              <Select
                value={myChoices[expanded]?myChoices[expanded]:''}
                multiple={false}
                native
                // @ts-ignore Typings are not considering `native`
                onChange={accordianSelectChanged}
                label="Native"
                inputProps={{
                  id: 'select-multiple-native',
                }}
              >
                <option key={'option_'+index} value={false}>
                    Select One
                  </option>
                {items.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </Select>
              <MKBox mt={2}><MKInput label="Your script" fullWidth multiline value={myTxt.script} rows={6} onChange={accordianTextChanged} /></MKBox>

            </FormControl>
            </Grid>
            <Grid item xs={3}><MKBox mt={1}><MKInput label="Example" fullWidth multiline value={myChoices[expanded]?txt[myChoices[expanded]].example:''} rows={9} /></MKBox></Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>)}</div>
        <MKBox mt={1}><MKInput label="Final Output" fullWidth multiline value={Object.keys(myChoices).sort().map(value=>{
          //console.log('value', myTxts[myChoices[value]].script)
          return myTxts[myChoices[value]].script+"\n\n"
          
        }).join("")} rows={20} /><MKButton onClick={()=>copyTextToClipboard(Object.keys(myChoices).sort().map(value=>{
          //console.log('value', myTxts[myChoices[value]].script)
          return myTxts[myChoices[value]].script+"\n\n"
          
        }).join(""))}>Copy to clipboard</MKButton></MKBox>
        
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
