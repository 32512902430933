/*
=========================================================
=========================================================

* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/designBlocksData";
import a from "../../../assets/json/options/1.json"
import b from "../../../assets/json/options/2.json"
import c from "../../../assets/json/options/3.json"
import d from "../../../assets/json/options/4.json"
import e from "../../../assets/json/options/5.json"
import f from "../../../assets/json/options/6.json"
import g from "../../../assets/json/options/7.json"
const questions = [a,b,c,d,e,f,g]
function DesignBlocks() {
  const renderData = questions.map(({ title, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        
      </Grid>
    </Grid>
  ));
  for(let a in questions){
    console.log(questions[a].title)

  }
  return (
    <MKBox component="section" my={6} py={6}>
      <Container sx={{ mt: 6 }}>
        {renderData}
      </Container>
    </MKBox>
  );
}

export default DesignBlocks;
