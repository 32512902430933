/*
=========================================================
=========================================================

* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import AboutUs from "pages/LandingPages/AboutUs";

export default function AboutUsPage() {
  return <AboutUs />;
}
